<template>
  <v-sheet elevation="1" class="course-card-border border cursor-pointer" :class="[$vuetify.breakpoint.mobile? 'mx-3 my-2':'', !gallery && 'my-5']" 
  :width="gallery ? '' : '100%'" @click="$router.push({name: 'User Course Details', params: {id: data.uuid}})">
    <v-sheet 
      v-if="gallery"
      :color="!data.image ? (data.course_card ? data.course_card.color : selectedColor)  : 'transparent'" 
      :style="gallery && 'height: 150px'" 
      class="hover border-top" >
      <v-menu offset-y v-if="!data.image ">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            :color="gallery ? 'white' : 'gray'"
            class="float-right"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon >
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>
          <v-list>
            <v-list-item>
              <v-menu right offset-x :close-on-content-click="menu2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn left text v-bind="attrs" v-on="on" class="text-capitalize roboto f12">
                      Change Color
                      <v-icon right>mdi-chevron-right</v-icon>
                    </v-btn>
                  </template>
                  <v-sheet width="250">
                    <v-color-picker
                      hide-canvas
                      hide-inputs
                      hide-sliders
                      hide-mode-switch
                      class="pa-2"
                      mode="rgba"
                      show-swatches
                      swatches-max-height="150"
                      v-model="selectedColor"
                      @update:color="changeColor"
                    />
                  </v-sheet>
                </v-menu>
            </v-list-item>
            <v-list-item>
              <v-btn text class="text-capitalize roboto f12" @click="changeFavorite">
                Mark as {{ favorite ? 'Unfavorite' : 'Favorite' }}
                <v-icon color="yellow" right v-if="favorite">
                  mdi-star
                </v-icon>
                <v-icon color="yellow" right v-else>
                  mdi-star-outline
                </v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
      </v-menu>
      <v-btn
        v-if="data.image && !gallery"
        icon
        color="yellow"
        class="float-right"
        @click.stop
        @click="changeFavorite"
      >
        <v-icon v-if="favorite">
          mdi-star
        </v-icon>
        <v-icon v-else>
          mdi-star-outline
        </v-icon>
      </v-btn>
      <v-img
        v-if="data.image && gallery"
        :src="data.image.url"
        :style="$vuetify.breakpoint.mobile ? gallery ? 'height: 150px; width: 100%' : 'height: 150px; width: 100%':gallery ? 'height: 150px; width: 100%' : 'height: 150px; width: 100%'"
        class="border-top"
        :max-height="'error' && 150"
        v-on:error="require('@/assets/images/course-placeholder-1.png')"
      >
        <v-btn
          icon
          color="yellow"
          class="float-right"
          @click.stop
          @click="changeFavorite"
        >
          <v-icon v-if="favorite">
            mdi-star
          </v-icon>
          <v-icon v-else>
            mdi-star-outline
          </v-icon>
        </v-btn>
      </v-img>
    </v-sheet>
    
    <v-sheet v-if="gallery" class="my-2 mx-2 d-flex flex-column ">
      <v-list-item dense class="px-0">
        <title-ellipse :title="data.title" font="f14"/>
      </v-list-item>
      <v-progress-linear 
        height="8"
        :value="progress"
        rounded
        color="success"
        background-color="grey lighten-3"
      ></v-progress-linear>
      <b class="poppins secondary-1--text fw600 f13 my-2">
        {{ progress }}% Complete
      </b>
    
      <v-btn v-if="progress === 0" dense block class="poppins f12 mb-3 text-capitalize" color="primary" @click="$router.push({name: 'User Course Details', params: {id: data.uuid}})">
        Start Course
      </v-btn>
      <v-btn v-else-if="progress === 100" dense block class="poppins f12 mb-3 text-capitalize" color="primary" @click="$router.push({name: 'User Course Details', params: {id: data.uuid}})">
        Completed
      </v-btn>
      <v-btn v-else dense block class="poppins f12 mb-3 text-capitalize" color="primary" @click="$router.push({name: 'User Course Details', params: {id: data.uuid}})">
        Continue Course
      </v-btn>
    </v-sheet>
    <!-- 
      @/assets/images/course-placeholder-1.png
       -->
    <v-alert
      v-if="!gallery"
      elevation="1"
    >
       <v-row class="pa-3">
        <v-col v-if="!data.image" cols="2" class="border" :style="`background-color: ${!data.image ? (data.course_card ? data.course_card.color : selectedColor)  : getPrimary}`" >
        
        </v-col>

        <v-col v-else cols="2" class="border" :style="`background-image: url(${data.image.url ? data.image.url : this.defaultImage}); background-size: cover`" >
          
        </v-col >
        <!-- <v-col v-else cols="2">
          <v-img
            :src="data.image.url"
            class="border"
            :max-height="'error' && 120"
            v-on:error="data.image.url = `${asset_path}/course-placeholder-1.png`"
          />
        </v-col> -->
        <!-- <v-col cols="2" :style="'background-color: pink'">
          <v-img
            v-if="data.image && data.image.url"
            :src="data.image.url"
            class="border"
            :max-height="'error' && 120"
            v-on:error="data.image.url = `${asset_path}/course-placeholder-1.png`"
          />
          <v-sheet
            v-else
            :color="!data.image ? (data.course_card ? data.course_card.color : selectedColor)  : 'primary'"
            :height="'error' && 120">
          </v-sheet>
        </v-col> -->
        <v-col>
          <section class="d-flex flex-column">
            <v-list-item light dense
              class="f14 poppins fw600 px-0"
              >
              {{ data.title }}
              <v-spacer/>
              <section class="hidden-sm-and-down">
                <v-btn v-if="progress === 0" dense class="poppins f12 mb-3 text-capitalize" color="primary" @click="$router.push({name: 'User Course Details', params: {id: data.uuid}})">
                  Start Course
                </v-btn>
                <v-btn v-else-if="progress === 100" dense class="poppins f12 mb-3 text-capitalize" color="primary" @click="$router.push({name: 'User Course Details', params: {id: data.uuid}})">
                  Completed
                </v-btn>
                <v-btn v-else dense class="poppins f12 mb-3 text-capitalize" color="primary" @click="$router.push({name: 'User Course Details', params: {id: data.uuid}})">
                  Continue Course
                </v-btn>
              </section>
            </v-list-item>
            
            <div v-if="data.description && data.description.length > 0" class="fw400 f12" v-html="data.description.length > 200 ? `${data.description.slice(0, 200)}...` : $dompurifier(data.description)">
            </div>
            <v-progress-linear 
              height="8"
              :value="progress"
              rounded
              color="success"
              background-color="grey lighten-3"
              class="mr-10"
            ></v-progress-linear>
            <b class="poppins secondary-1--text fw600 f13">
              {{ progress }}% Complete
            </b>
          </section>
        </v-col>
      </v-row>
    </v-alert>
  </v-sheet>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  props: ['i', 'data', 'progress', 'gallery'],
  data: () => ({
    menu1: false,
    menu2: false,
    selectedColor: '#1565C0FF',
    favorite: false,
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'gre-darken-1'],
    asset_path: `${process.env.VUE_APP_ASSET_PATH}`,
    defaultImage: `${process.env.VUE_APP_ASSET_PATH}/course-placeholder-1.png`
  }),
  mounted(){
    // console.log(this.data)
  },

  methods: {
    ...mapActions('usr', ['updateCourseColor', 'updateCourseFavorite', 'updateCourseFavorite']),

    changeColor() {
      if (this.data.course_card) {
        this.data.course_card.color = this.selectedColor
      }
      
      this.updateCourseColor({
        course_id: this.data.id,
        color: this.selectedColor
      })
    },

    changeFavorite() {
      this.favorite = !this.favorite

      this.updateCourseFavorite({
        course_id: this.data.id,
        is_favorite: this.favorite
      })
    }
  },

  computed: {
    getPrimary() {
      return this.$vuetify.theme.themes.light.primary
    }
  }
}
</script>
